<template>
    <div>
        <div class="breadcrumb">
            <h1>Registro de Empresa</h1>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-3">
                                <router-link :to="{ name: 'empresa-create' }">
                                    <button class="btn th-custom-color">Nuevo</button>
                                </router-link>
                            </div>
                            <div class="col-sm-3 offset-sm-6">
                                <div class="text-right">
                                    <label class="switch switch-success mr-3">
                                        <input id="filterbyState"
                                        type="checkbox"
                                        :checked="estado"
                                        @change="listarEmpresa"
                                        v-model="estado"
                                        >
                                        <span class="slider"></span>
                                        <span>{{ labelActivos }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-1">
                        <div class="col-sm-3">
                            <div class="btn-group">
                                <span class="pt-2 mr-1">Mostrar </span>
                                <b-form-select
                                    v-model="porPagina"
                                    :options="paginaOpciones">
                                </b-form-select>
                                <span class="pt-2 ml-1"> Registros</span>
                            </div>
                        </div>
                        <div class="col-sm-3 offset-sm-6 text-right">
                            <div class="form-group row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <input type="text"
                                    class="form-control"
                                    v-model="filter"
                                    @keyup="filtrarBusqueda"
                                    autocomplete="off"
                                    placeholder="Buscar"
                                    >
                                </div>
                            </div>
                        </div>
                        <div style="display: block; width: 100%">
                            <b-table
                                class="table table-striped table-bordered table-hover"
                                :fields="fields"
                                :busy="isLoading"
                                :items="tablaEmpresas"
                                :per-page="porPagina"
                            >
                            <template #cell(editar)="row" v-if="estado">
                                <router-link
                                :to="{ name: 'empresa-edit', params: {id: encrypt(row.item.id)}}"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Editar">
                                    <i class="nav-icon i-Pen-5 text-success font-weight-bold"
                                    title="editar" style="cursor: pointer;">
                                    </i>
                                </router-link>
                            </template>
                            <template #cell(eliminar)="row" v-if="estado">
                                <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                                style="cursor: pointer" title="eliminar"
                                @click="showDeleteConfirmation(row.item)"></i>
                            </template>
                            <template #cell(certificado_digital)="data" v-if="estado">
                                <i class="nav-icon i-Library font-weight-bold text-warning"
                                style="cursor: pointer" title="Firma Digital"
                                @click="showFirmaDigital(data.item)"></i>
                            </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
            <ModalFirmaDigital />
            <TheModalDestroy/>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import ModalFirmaDigital from '../components/modalFirmaDigital.vue'
import TheModalDestroy from '../components/theModalDestroy.vue';

export default {
    name: 'index',
    components: {
        ModalFirmaDigital,
        TheModalDestroy,
    },
    data() {
        return {
            delayTimeout: null,
        };
    },
    created() {
        this.listarEmpresa();
    },
    methods: {
        ...call('empresa/empresaIndex', [
            "listarEmpresa",
        ]),
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.listarEmpresa, DELAY);
        },
        encrypt(id) {
            return btoa(id);
        },
        showDeleteConfirmation(item) {
            this.modalDestroyConfirmation = true;
            this.registro = item;
        },
        showFirmaDigital(item){
            this.showModalFirmaDigital = true
            this.companyFirma = item
        },
    },
    computed: {
        labelActivos() {
        if (this.estado) {
            return 'Activos';
        }
        return 'Inactivos';
        },
        ...get('empresa/empresaIndex', [
            "tablaEmpresas",
            "fields",
            "paginaOpciones",
            "isLoading"
        ]),
        ...sync('empresa/empresaIndex', [
            "estado",
            "porPagina",
            "paginaActual",
            "paginaOpciones",
            "totalFila",
            "filter"
        ]),
        ...sync('empresa/firmaDigital', [
            'showModalFirmaDigital',
            'companyFirma'
        ]),
        ...sync('empresa/empresaDestroy', [
            'modalDestroyConfirmation',
            'registro'
        ]),
    },
}
</script>

<style scoped>
    .btn-orange{
        color: white;
        background-color: orange;
    }
</style>