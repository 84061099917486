
<template>
    <b-modal size="lg" class="modal-title" v-model="showModalFirmaDigital"  hide-footer>
        <template slot="modal-title">
            <h5 v-if="companyFirma"><strong>FIRMA DIGITAL: ID: {{companyFirma.numero}} - {{ companyFirma.nombre_comercial }}</strong></h5>
        </template>
        <div class="modal-body">
					<form v-on:submit.prevent="registraFirmaDigital" autocomplete="off">
						<div class="row form-group">
							<div class="col-sm-1 pr-0 text-right">
								<label class="col-form-label">Llave Privada:</label>
							</div>
							<div class="col-sm-2">
								<input type="text"
									class="form-control"
									autocomplete="off"
									v-model="llavePrivada"
								>
							</div>
							<div class="col-sm-1 pr-0 text-right">
								<label class="col-form-label">Llave Publica:</label>
							</div>
							<div class="col-sm-2">
								<input type="text"
									class="form-control"
									autocomplete="off"
									v-model="llavePublica"
								>
							</div>

							<div class="col-sm-1 pr-0 text-right">
								<label class="col-form-label">Vence:</label>
							</div>
							<div class="col-sm-2">
								<date-picker
									class="col-sm-9 px-0"
									v-model="vence"
									lang="es"
									format="DD/MM/YYYY"
									type="date"
									value-type="date"
								></date-picker>
							</div>
							<div col-sm-2>
								<button class="btn th-custom-color" type="submit">Agregar</button>
							</div>
							
						</div>
					</form>

					<div style="display: block; width: 100%">
						<b-table
							class="table table-striped table-bordered table-hover"
							:fields="fieldsFirmaDigital"
							:busy="isLoadingFirmas"
							:items="tablaFirmas"
						>
						</b-table>
					</div>
        </div>
    </b-modal>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';

export default {
    name: 'modalFirmaDigital',
    data() {
        return {

        };
    },
		components: {
			DatePicker,
		},
    methods: {
        async registraFirmaDigital() {
            // const ELIMINAR_OK = await this.eliminar(this.registro);
            // if (ELIMINAR_OK) {
            //     this.listarPaquetes();
            // }
        },
        ...call('paquete/paqueteDestroy', ['eliminar']),
        ...call('paquete/paqueteIndex', ['listarPaquetes'])
    },
    computed: {
        ...get('empresa/firmaDigital', [
            'companyFirma',
						'fieldsFirmaDigital',
						'isLoadingFirmas',
						'tablaFirmas',
        ]),
        ...sync('empresa/firmaDigital', [
            'showModalFirmaDigital',
						'llavePrivada',
						'llavePublica',
						'vence',
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('paquete/paqueteDestroy/reset');
    }
}
</script>